import {lazy} from "react"
import {Switch} from "react-router-dom"

import {FTLErrorPage} from "ftl-uikit"
import {PrivateRoute} from "ftl-core"
// import AttendanceList from "./pages/Attendance/List"

const OrderWaybillTemplatePage = lazy(
    () => import("../pages/OrderWaybillTemplate/List")
)

const OrderWaybillPage = lazy(() => import("../pages/OrderWaybill/List"))
const DetailOrderWaybillPage = lazy(() => import("../pages/OrderWaybill/Detail"))
const OrderWaybillDetailPage = lazy(() => import("../pages/OrderWaybill/OrderWaybillDetailPage/pages/OrderWaybillDetailPage"))

//Attendance
const AttendanceList = lazy(() => import("../pages/Attendance/List"))
const AttendanceDetail = lazy(() => import("../pages/Attendance/Detail"))

export const Routes = () => {
    return (
        <Switch>
            <PrivateRoute exact path="/order-waybill-templates">
                <OrderWaybillTemplatePage/>
            </PrivateRoute>

            <PrivateRoute exact path="/order-waybills">
                <OrderWaybillPage/>
            </PrivateRoute>
            <PrivateRoute exact path="/order-waybills/:id">
                <OrderWaybillDetailPage/>
            </PrivateRoute>
            <PrivateRoute exact path="/order-waybills-old/:id">
                <DetailOrderWaybillPage/>
            </PrivateRoute>


            <PrivateRoute exact path="/attendance">
                <AttendanceList/>
            </PrivateRoute>
            <PrivateRoute exact path="/attendance/new">
                <AttendanceDetail pageType="new"/>
            </PrivateRoute>
            <PrivateRoute exact path="/attendance/:id">
                <AttendanceDetail pageType="edit"/>
            </PrivateRoute>

            <PrivateRoute path={["*", "/404"]}>
                <FTLErrorPage
                    title="Страницы не существует"
                    message="Наверное, вы перешли по неправильной ссылке."
                />
            </PrivateRoute>
        </Switch>
    )
}
