// import { createEffect, attach, createStore } from "effector"
import { AxiosResponse } from "axios"
import { AuthClient } from "ftl-core"

//types
import {
  createUtilsRepository,
  createRepositoryInstance,
  createProfileRepository,
  createEntityRepository,
  BasicResponse,
} from "ftl-core"
import {
  Nomenclature,
  NomenclatureRequest,
} from "../../../domains/base/entities/nomenclature"
import {
  OrderWaybill,
  OrderWaybillRequest,
} from "../../../domains/base/entities/orderWaybill"
import {
  OrderWaybillTemplate,
  OrderWaybillTemplateRequest,
} from "../../../domains/base/entities/orderWaybillTemplate"
import { Warehouse, WarehouseRequest } from "../../../domains/base/entities/warehouse"
import { Attendance, AttendanceRequest } from "../../../domains/base/entities/attendance"
import { Department, DepartmentEntity, DepartmentRequest } from "../../../domains/base/entities/department"
import { Employee, EmployeeRequest } from "../../../domains/base/entities/employee"
import { Position, PositionRequest } from "../../../domains/base/entities/position"

enum Versions {
  v1 = "v1",
  v2 = "v2",
}

export const authApiBaseUrl = process.env.REACT_APP_FTL_AUTH_BASE_URL
  ? process.env.REACT_APP_FTL_AUTH_BASE_URL
  : "http://localhost:9102"
export const apiBaseUrl = process.env.REACT_APP_FTL_CRM_BASE_URL
  ? process.env.REACT_APP_FTL_CRM_BASE_URL
  : "http://localhost:42069"

export const baseUrl =
  process.env.REACT_APP_ENV === "dev"
    ? `${apiBaseUrl}/api/${Versions.v1}`
    : `${apiBaseUrl}/api/${Versions.v1}`

// Auth

export const repository = createRepositoryInstance({
  baseUrl,
  tokenRefresh: () => AuthAPI.refresh(),
  authErrors: {
    reasonKey: "reason",
    tokenExpired: ["ACCESS_TOKEN_EXPIRED"],
    permissionInsufficient: ["CREDENTIAL_PERMISSION_INSUFFICIENT"],
    needToLogin: [
      "CREDENTIAL_BLOCKED",
      "ACCESS_TOKEN_INACTIVATED",
      "ACCESS_TOKEN_NOT_EXIST",
      "ACCESS_TOKEN_REFRESHED",
      "ACCESS_TOKEN_MODIFIED",
      "ACCESS_TOKEN_MALFORMED",
    ],
  }
})

export const AuthAPI = new AuthClient(
  repository,
  {
    signIn: `${authApiBaseUrl}/not-secure/api/${Versions.v1}/auth/login/password`,
    signOut: `${authApiBaseUrl}/not-secure/api/${Versions.v1}/auth/logout`,
    refresh: `${authApiBaseUrl}/not-secure/api/${Versions.v1}/auth/refresh`,
  },
  {
    loginPropName: "username",
  }
)

// Utils

export const UtilsAPI = createUtilsRepository(repository)

//Profile

export const ProfileAPI = createProfileRepository(repository)

//Picture

export const PictureAPI = Object.freeze({
  post: (request: FormData): Promise<AxiosResponse<{ result: string }>> => {
    return repository.post(`pictures`, request)
  },
})

// // User-waybills

// const normalizeUserWaybillsPayload = (payload: UserWaybill): UserWaybill => ({
//   email: payload.email,
//   warehouseId: payload.warehouseId,
//   posCode: payload.posCode,
//   supWarehouseCode: payload.supWarehouseCode,
//   password: payload.password,
// })

// export const UserWaybillAPI = createEntityRepository<
//   UserWaybillRequest,
//   UserWaybill<"GET">,
//   UserWaybill,
//   UserWaybill<"PATCH">
// >({
//   repositoryInstance: repository,
//   normalizeEntityPayload: normalizeUserWaybillsPayload,
//   path: "user-waybills",
//   utils: UtilsAPI,
// })

// Warehouses

const normalizeWarehousePayload = (payload: Warehouse): Warehouse => ({
  title: payload.title,
})

export const WarehouseAPI =Object.freeze({
  ...createEntityRepository<
  WarehouseRequest,
  Warehouse<"GET">,
  Warehouse,
  Warehouse<"PATCH">
>({
  repositoryInstance: repository,
  normalizeEntityPayload: normalizeWarehousePayload,
  basePath: "warehouses",
  utils: UtilsAPI,
}),
getWareHouseId: (  
): Promise<AxiosResponse<BasicResponse<Warehouse<"GET">>>> =>
  repository.get(`/warehouses/warehouse-by-credential`),
getWareHouse: (  
  id: string,
  ): Promise<AxiosResponse<BasicResponse<Warehouse<"GET">>>> =>
    repository.get(`/warehouses/${id}`)
})
// Order-waybill-tempaltes

const normalizeOrderWaybillTemplatePayload = (
  payload: OrderWaybillTemplate
): OrderWaybillTemplate => ({
  title: payload.title,
  nomenclatures: payload.nomenclatures,
  warehouseIds: payload.warehouseIds,
})

export const OrderWaybillTemplateAPI = createEntityRepository<
  OrderWaybillTemplateRequest,
  OrderWaybillTemplate<"GET">,
  OrderWaybillTemplate,
  OrderWaybillTemplate<"PATCH">
>({
  repositoryInstance: repository,
  normalizeEntityPayload: normalizeOrderWaybillTemplatePayload,
  basePath: "order-waybill-templates",
  pathModifiers: {
    getList: () => "/user",
  },
  utils: UtilsAPI,
})

// Order-waybills

const normalizeOrderWaybillPayload = (
  payload: OrderWaybill<"POST"> | OrderWaybill<"PATCH">,
  type?: "post" | "patch"
) => {
  if (type === "post")
    return {
      nomenclatures: payload.nomenclatures,
      warehouseId: payload.warehouseId,
      status: payload.status,
      comment: payload.comment,
      name: payload.name,
      phone: payload.phone,
      uuid: payload.uuid,
      orderWaybillTemplateId: (payload as OrderWaybill<"POST">)
        .orderWaybillTemplateId,
    } as OrderWaybill<"POST">
  else
    return {
      nomenclatures: payload.nomenclatures,
      warehouseId: payload.warehouseId,
      status: payload.status,
      comment: payload.comment,
      name: payload.name,
      phone: payload.phone,
      uuid: payload.uuid,
    } as OrderWaybill<"PATCH">
}

export const OrderWaybillAPI = Object.freeze({
  ...createEntityRepository<
    OrderWaybillRequest,
    OrderWaybill<"GET">,
    OrderWaybill<"POST">,
    OrderWaybill<"PATCH">
  >({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizeOrderWaybillPayload,
    basePath: "order-waybills",
    pathModifiers: {
      getList: () => "/my-order-waybills",
    },
    utils: UtilsAPI,
  }),
  edit: (
    id: string,
    data: OrderWaybill<"PATCH">
  ): Promise<AxiosResponse<BasicResponse<OrderWaybill<"GET">>>> =>
    repository.put(
      "/order-waybills/" + id,
      normalizeOrderWaybillPayload(data, "patch")
    ),
  createFromTemplate: (
    orderWaybillTemplateId: string
  ): Promise<AxiosResponse<BasicResponse<OrderWaybill<"GET">>>> =>
    repository.post("order-waybills/create-from-template", {
      orderWaybillTemplateId,
    }),
  send: (
    id: string
  ): Promise<AxiosResponse<BasicResponse<OrderWaybill<"GET">>>> =>
    repository.post(`order-waybills/${id}/send-order`),
  delete: (
    id: string
  ): Promise<AxiosResponse<BasicResponse<OrderWaybill<"GET">>>> =>
    repository.delete("/order-waybills/" + id),
})

// Order-waybills

const normalizeNomenclaturesPayload = (
  payload: Nomenclature
): Nomenclature => ({
  uuid: payload.uuid,
  name: payload.name,
  description: payload.description,
  type: payload.type,
  code: payload.code,
  categoryId: payload.categoryId,
  taxCategoryId: payload.taxCategoryId,
  mainUnitId: payload.mainUnitId,
  defaultSalePrice: payload.defaultSalePrice,
  modifierIds: payload.modifierIds,
  photos: payload.photos,
})

export const NomenclatureAPI = createEntityRepository<
  NomenclatureRequest,
  Nomenclature<"GET">,
  Nomenclature,
  Nomenclature<"PATCH">
>({
  repositoryInstance: repository,
  normalizeEntityPayload: normalizeNomenclaturesPayload,
  basePath: "nomenclatures",
  utils: UtilsAPI,
})

//Attendance
const normalizeAttendancePayload = (
  payload: Attendance | Attendance<"PATCH">
): Omit<Attendance | Attendance<"PATCH">, "id" | "createdAt"> => {
  const normalized: any = {
    id: payload.id,
    comment: payload.comment,
    departmentId: payload.departmentId,
    managerConfirm: payload.managerConfirm,
    employeeId: payload.employeeId,
    positionId: payload.positionId,
    duration: payload.duration,
    status: payload.status,
    syncStatus: payload.syncStatus,
    uuid: payload.uuid,
    dateEnd: payload.dateEnd,
    dateStart: payload.dateStart,
  }
  return normalized
}
export const AttendanceAPI = Object.freeze({
  ...createEntityRepository<
    AttendanceRequest,
    Attendance<"GET">,
    Attendance,
    Attendance<"PATCH">
  >({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizeAttendancePayload,
    basePath: "attendances/by-credential",
    pathModifiers: {
      create: () => "attendances",
    },
    utils: UtilsAPI,
  }),
  getByCredential: ():
    Promise<AxiosResponse<BasicResponse<{ count: number, items: DepartmentEntity[] }>>> =>
    repository.get("departments/by-credential"),
  create: (payload: Attendance):
    Promise<AxiosResponse<BasicResponse<Attendance<"GET">>>> =>
    repository.post("attendances", normalizeAttendancePayload(payload)),
  edit: (id: string | undefined, payload: Attendance):
    Promise<AxiosResponse<BasicResponse<Attendance<"GET">>>> =>
    repository.put(`attendances/by-credential/${id}`, normalizeAttendancePayload(payload)),
})


//Department
const normalizeDepartmentPayload = (
  payload: Department<"POST"> | Department<"PATCH">,
): Department => ({
  id: payload.id,
  title: payload.title,
  orgStructureId: payload.orgStructureId,
  warehouseId: payload.warehouseId,
  cityId: payload.cityId,
  priceCategoryId: payload.priceCategoryId,
  status: payload.status,
  legalUnitId: payload.legalUnitId,
  point: payload.point,
  code: payload.code,
  corpStructureId: payload.corpStructureId,
  locationPlans: payload.locationPlans,
  startWorkDate: payload.startWorkDate,
})

export const DepartmentAPI = Object.freeze({
  ...createEntityRepository<DepartmentRequest, Department<"GET">>({
    repositoryInstance: repository,
    basePath: "departments",
    utils: UtilsAPI,
    normalizeEntityPayload: normalizeDepartmentPayload,
  }),
})

//Employee
const normalizeEmployeePayload = (
  payload: Employee | Employee<"PATCH">
): Omit<Employee | Employee<"PATCH">, "id" | "createdAt"> => {
  const normalized: any = {
    id: payload.id,
    createdAt: payload.createdAt,
    deleted: payload.deleted,
    fullName: payload.fullName,
    emails: payload.emails,
    personalNumber: payload.personalNumber,
    status: payload.status,
    positions: payload.positions,
    updatedAt: payload.updatedAt,
    phones: payload.phones,
  }
  return normalized
}
export const EmployeeAPI = Object.freeze({
  ...createEntityRepository<
    EmployeeRequest,
    Employee<"GET">,
    Employee,
    Employee<"PATCH">
  >({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizeEmployeePayload,
    basePath: "employees",
    utils: UtilsAPI,
  }),
  employeeById: (
    id: string,
  ): Promise<AxiosResponse<BasicResponse<any>>> => {
    return repository.get(
      `employees?departmentId=${id}`
    )
  },
  getEmployeeById: (
    id: string
  ): Promise<AxiosResponse<BasicResponse<any>>> => {
    return repository.get(
      `employees/${id}`
    )
  },
})

//Position
const normalizePositionPayload = (
  payload: Position | Position<"PATCH">
): Omit<Position | Position<"PATCH">, "id" | "createdAt"> => {
  const normalized: any = {
    id: payload.id,
    createdAt: payload.createdAt,
    deleted: payload.deleted,
    updatedAt: payload.updatedAt,
    condition: payload.condition,
    description: payload.description,
    title: payload.title,
    categoryId: payload.categoryId,
  }
  return normalized
}
export const PositionAPI = Object.freeze({
  ...createEntityRepository<
    PositionRequest,
    Position<"GET">,
    Position,
    Position<"PATCH">
  >({
    repositoryInstance: repository,
    normalizeEntityPayload: normalizePositionPayload,
    basePath: "positions",
    utils: UtilsAPI,
  }),
  get: ():
    Promise<AxiosResponse<BasicResponse<{ count: number, items: Position[] }>>> =>
    repository.get("positions"),
  getById: (id: string): 
    Promise<AxiosResponse<BasicResponse<Position>>> => {
      return repository.get(`positions/${id}`)
    },
})

