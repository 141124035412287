import { snackbarService } from "ftl-core"
import { useEffect, useState } from "react"
import { WarehouseAPI } from "../../../infrastructure/api/base"

const WareHouseList = () => {

    const [warehouseid, setWarehouseId] = useState()
    const [warehouseData, setWarehouseData] = useState()

    useEffect(() => {
        WarehouseAPI.getWareHouseId().then(response => {
            setWarehouseId(response.data.result)
        }, (error) => {
            snackbarService.show({ variant: "error", message: "Произошла ошибка при получении названия склада" })
        }
        )
    }, [])

    useEffect(() => {
        if (warehouseid) {
            WarehouseAPI.getWareHouse(warehouseid).then(response => {
                setWarehouseData(response.data.result.name)
            }, (error) => {
                snackbarService.show({ variant: "error", message: "Произошла ошибка при получении названия склада" })
            }
            )            
        }
    }, [warehouseid])
    
    return (
        <div>{warehouseData}</div>
    )
}

export default WareHouseList