import { UIMain } from "ftl-dashboards-templates"

import { Routes } from "./app/routes/Routes"

import { AuthAPI, ProfileAPI } from "./infrastructure/api/base/index"
import {
  basicReducer,
  initialState,
  setProfileAction,
  handleError,
} from "ftl-core"
import { createStore } from "redux"
import OrderWaybillTemplateIcon from "./app/utils/icons/OrderWaybillTemplateIcon"
import { FileIcon, theme } from "ftl-uikit"
import { projectTitle } from "./domains/base/constants/ProjectTitle"
import PersonPinCircleIcon from "./app/utils/icons/PersonPinCircleIcon"
import WareHouseList from "./app/pages/Warehouse/List"

const store = createStore(
  basicReducer,
  initialState,
  //@ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export const App = () => {
  return (
    <UIMain
      redirectOnAuthUrl="/order-waybills"
      project={{ title: projectTitle }}
      theme={theme}
      StoreProviderProps={{
        store,
        getProfile: () => {
          //заглушка: единственный способ избежать вытягивание профиля при загрузке приложения
          return Promise.resolve({
            data: {
              result: {
                id: "",
                createdAt: "",
                email: "",
                firstName: "",
                lastName: "",
                accountStatus: "",
              },
            },
            status: 200,
            statusText: "OK",
            headers: {},
            config: {},
          })
        },
      }}
      AuthPageProps={{
        fieldProps: {
          email: {
            label: "Логин",
          },
        },
      }}
      AuthClient={AuthAPI}
      BaseLayoutProps={{
        onMenuClick: async () => {
        },
        sideMenuItems: [
          {
            icon: <FileIcon />,
            label: "Мои заявки",
            to: "/order-waybills",
          },
          {
            icon: <OrderWaybillTemplateIcon />,
            label: "Шаблоны",
            to: "/order-waybill-templates",
          },
          {
            to: "/attendance",
            label: "Явки",
            icon: <PersonPinCircleIcon />,
          },
        ],
      }}
    >
      <WareHouseList />
      <Routes />
    </UIMain>
  )
}

export default App
